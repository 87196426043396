/*
 * Copyright 2024 Alexander Orlov <alexander.orlov@loxal.net>. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

package net.loxal.hutils.client

import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.*
import org.w3c.dom.events.Event
import org.w3c.dom.url.URLSearchParams
import org.w3c.workers.ServiceWorkerRegistration
import org.w3c.xhr.XMLHttpRequest

private val documentElement: Element? = document.documentElement
private val previousPageContent = document.body?.innerHTML
private val mainPageTitle = documentElement?.getElementsByTagName("title")?.get(0)?.textContent
private val keywordsContent =
    (documentElement?.getElementsByTagName("meta")?.namedItem("keywords") as HTMLMetaElement?)?.content

private lateinit var instance: Client
private fun main() {
    if (!window.location.search.contains("standalone")) {
        Client.loadPageIntoContainer()
    }

    instance = Client()
    console.asDynamic().debug("window.onload - SiteKit")
}

class Client {
    init {
        console.info(
            "%c%s",
            "color: hsla(33, 99%, 44%, 1); background: hsla(222, 99%, 44%, .3); font-size: 2em; font-weight: bold; border-radius: .5em;",
            " SiteKit loaded \uD83D\uDEE0️ Simple. Craftsmanship. Evolving. Passion.\uD83D\uDD25 "
        )

        setupServiceWorker()
        document.dispatchEvent(Event("loxal.sitekit-init"))
//        injectTracker()
        injectSearchbar()
    }

    private fun setupServiceWorker() {
        val isServiceWorkerAvailable: () -> Boolean = {
            (window.location.hostname === "localhost") xor (window.location.protocol === "https:")
        }
        if (isServiceWorkerAvailable()) {
            val serviceWorker = window.navigator.serviceWorker
            serviceWorker.register("/service-worker.js")
                .then(onFulfilled = { serviceWorkerRegistration: ServiceWorkerRegistration ->
                    serviceWorkerRegistration.unregister()
                }).catch { throwable: Throwable ->
                    console.warn("Registration failed with $throwable")
                }
        }
    }

    companion object {
        init {
            document.addEventListener("loxal.sitekit-container-template-fetched", {
                applyContainerMetadata()
                applyLanguage()
                applySiteProperties()
            })
            console.asDynamic().debug("companion object")
        }

        private fun applyLanguage() {
            window.onlanguagechange = {
                console.warn("LANGUAGE CHANGED")
            }
            val language = document.getElementById("language") as HTMLLabelElement
            val languageSelector = document.getElementById("languageSelector") as HTMLSelectElement
            val languageVariant = URLSearchParams(window.location.search).get("language")
            if (!languageVariant.isNullOrEmpty()) {
                language.style.display = "inline-block"
                languageSelector.asDynamic().value = languageVariant
            }
            languageSelector.onchange = {
                console.warn(languageSelector.selectedOptions[0]?.textContent)
                console.warn(languageSelector.selectedOptions[0]?.getAttribute("value"))
            }
        }

        private fun applySiteProperties() {
            val xhr = XMLHttpRequest()
            xhr.open("GET", "/stats")
            xhr.send()
            xhr.onload = {
                val versionContainer = document.getElementById("title") as HTMLHeadingElement
                if (xhr.status == 200.toShort()) {
                    val stats = JSON.parse<dynamic>(xhr.responseText)
                    versionContainer.title = "App version: b${stats.buildNumber}-${stats.scmHash}"
                }
            }
        }

        internal fun loadPageIntoContainer() {
            val containerTemplatePageContainer = "/main.html"
            if (window.location.pathname !== containerTemplatePageContainer) {
                val xhr = XMLHttpRequest()
                xhr.open("GET", containerTemplatePageContainer)
                xhr.send()
                xhr.onload = {
                    val containerTemplatePage = xhr.responseText
                    val assembledPage = containerTemplatePage.replaceFirst("></main", ">${previousPageContent}</main")
                    document.documentElement?.innerHTML = assembledPage
                    document.dispatchEvent(Event("loxal.sitekit-container-template-fetched"))
                }
            }
        }

        private fun injectTracker() {
            val trackerCode = document.createElement("script")
            trackerCode.textContent =
                """
    (function (d, w, c) {
        (w[c] = w[c] || []).push(function() {
            try {
                w.yaCounter86389866 = new Ya.Metrika({
                    id:86389866,
                    clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true
                });
            } catch(e) { }
        });

        var n = d.getElementsByTagName("script")[0],
            x = "/asset-cache/aHR0cHM6Ly9jZG4uanNkZWxpdnIubmV0L25wbS95YW5kZXgtbWV0cmljYS13YXRjaC93YXRjaC5qcw==",
            s = d.createElement("script"),
            f = function () { n.parentNode.insertBefore(s, n); };
        for (var i = 0; i < document.scripts.length; i++) {
            if (document.scripts[i].src === x) { return; }
        }
        s.type = "text/javascript";
        s.async = true;
        s.src = x;

        if (w.opera == "[object Opera]") {
            d.addEventListener("DOMContentLoaded", f, false);
        } else { f(); }
    })(document, window, "yandex_metrika_callbacks");
                """.trimMargin()
            document.body?.appendChild(trackerCode)
        }

        private fun injectSearchbar() {
            val searchbar = document.createElement("script")
            searchbar.setAttribute("src", "/app/finder/module.min.js")
            searchbar.setAttribute("data-siteId", "a9ede989-9d94-41d1-8571-a008318b01db")
            searchbar.setAttribute("data-analyticsTrackingId", "UA-1343778-10")
            searchbar.setAttribute("data-append-as-child-to", "#searchbar")
            searchbar.setAttribute(
                "data-search-style",
                "width: 25rem; font-size: 2rem; border-radius: 0; text-indent: .5rem; border: hidden; opacity: .8;"
            )
            searchbar.setAttribute("defer", "")
            document.body?.appendChild(searchbar)
        }

        private fun applyContainerMetadata() {
            val keywords = document.getElementById("keywords") as HTMLHeadingElement
            keywords.textContent = "About: ${if (keywordsContent.isNullOrBlank()) "loxal" else keywordsContent}"

            val titleSuffix = " | loxal"
            document.documentElement?.getElementsByTagName("title")?.get(0)?.textContent =
                if (mainPageTitle.isNullOrBlank()) {
                    titleSuffix
                } else {
                    "$mainPageTitle$titleSuffix"
                }
        }
    }
}
